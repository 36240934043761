import gql from 'graphql-tag';

// For old build communication
export const ARMS_LOGIN_MUTATION = gql`
  mutation ArmsLogin($data: UserLoginInput) {
    armsLogin(data: $data) {
      success
      token
      refresh_token
      permissions
      user {
        id
        first_name
        last_name
        employee_info {
          employee_bio {
            profile_image
          }
        }
      }
    }
  }
`;

export const ARMS_REFRESH_TOKEN = gql`
  mutation ArmsRefreshToken($token: String!) {
    armsRefreshToken(token: $token) {
      access_token
      refresh_token
    }
  }
`;

export const ARMS_FORGOT_PASSWORD = gql`
  mutation sendPasswordResetLink($email: String!) {
    sendPasswordResetLink(email: $email) {
      success
      message
    }
  }
`;
export const ARMS_RESET_PASSWORD = gql`
  mutation resetPassword($data: PasswordResetInput!) {
    resetPassword(data: $data) {
      success
      message
    }
  }
`;

export const ARMS_CREATE_NEW_PASSWORD = gql`
  mutation ArmsCreateNewPassword($data: armsCreateNewPasswordInput) {
    armsCreateNewPassword(data: $data) {
      success
      message
    }
  }
`;

// export const ARMS_CHANGE_PASSWORD = gql`
// mutation armsChangePassword($data: PasswordChangeInput!) {
//   armsChangePassword(data: $data) {
//       success
//       message
//   }
// }
// `;

export const LOGIN_MUTATION = gql`
  mutation login($data: UserLoginInput!) {
    loginUser(data: $data) {
      token
      user {
        first_name
        last_name
        email
      }
    }
  }
`;
// export const FORGOT_PASSWORD = gql`
// mutation forgetPassword($email: String!) {
//   forgetPassword(email: $email) {
//       id
//       first_name
//       last_name
//       email
//   }
// }
// `;
// export const CHANGE_PASSWORD = gql`
// mutation changePassword($data: changePasswordInput!) {
//   changePassword(data: $data) {
//       id
//       first_name
//       last_name
//       email
//   }
// }
// `;
// export const CREATE_NEW_PASSWORD = gql`
// mutation createNewPassword($data: createNewPasswordInput!) {
//   createNewPassword(data: $data) {
//       id
//       first_name
//       last_name
//       email
//   }
// }
// `;
export const CREATE_USER = gql`
  mutation CreateUser(
    $data: UserCreateInput
    $onboardingData: onboardingPacketInput
  ) {
    createUser(data: $data, onboardingData: $onboardingData) {
      token
      user {
        id
        last_name
        first_name
        email
        other_name
        personal_email
      }
    }
  }
`;
export const CREATE_EMPLOYEE_INFO = gql`
  mutation createEmployeeInfo($data: CreateEmployeeInfoInput!) {
    createEmployeeInfo(data: $data) {
      id
      user {
        id
        first_name
        last_name
        other_name
      }
      department {
        id
        department_name
      }
      position {
        id
        position_name
      }
      location {
        country_name
        state_region
        town
        country_initials
        state_region_initials
      }
      starting_date
      office {
        id
        office_name
      }
      organization_id
    }
  }
`;
export const CREATE_EMPLOYEE_CONTACT = gql`
  mutation createEmployeeContact($data: CreateEmployeeContactInput) {
    createEmployeeContact(data: $data) {
      id
      user_id
      work_email
      personal_email
    }
  }
`;
export const CREATE_EMPLOYEE_BIO = gql`
  mutation createEmployeeBio($data: CreateEmployeeBioInput!) {
    createEmployeeBio(data: $data) {
      id
      user_id
      full_name
      gender
    }
  }
`;
export const DELETE_BIO = gql`
  mutation deleteEmployeeBio($data: DeleteEmployeeBioInput!) {
    deleteEmployeeBio(data: $data) {
      id
      user_id
      full_name
      gender
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation deactivateUser($data: deactivateUserInput!) {
    deactivateUser(data: $data) {
      id
      deleted_date
      delete_reason
      delete_comment
      deleted
    }
  }
`;
export const DEACTIVATE_USER_BIO = gql`
  mutation deactivateUserOnBio($data: deactivateBioUserInput!) {
    deactivateUserOnBio(data: $data) {
      id
      deleted
      delete_reason
      delete_comment
    }
  }
`;
export const ACTIVATE_USER = gql`
  mutation activateUser($id: ID!) {
    activateUser(id: $id) {
      id
      deleted
    }
  }
`;
export const ACTIVATE_USER_BIO = gql`
  mutation activateUserOnBio($data: activateBioUserInput!) {
    activateUserOnBio(data: $data) {
      id
      profile_image
      user_id
      deleted
    }
  }
`;
