<template>
  <div class="w-[366px] p-4 rounded-lg shadow-lg">
    <div class="w-full">
      <div>
        <img src="/img/sessionExpired.gif" alt="session expired gif" />
      </div>
      <div class="text-center N800 mt-4">
        Session expires in
        <span class="text-center N900 H500">{{ displayTime }}</span
        ><br />
        Click button to stay on page
      </div>
      <div class="flex justify-center mt-4">
        <ButtonComponent button-label="Stay on page" @submit="handleClick" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ButtonComponent from '@/ui-kit/button/ButtonComponent.vue';
import { ref, onMounted, computed, watch, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { formatTime } from '@/helpers/utils';
const router = useRouter();

const props = defineProps({
  stopCount: Boolean,
  startCount: Boolean
});
const emit = defineEmits(['refresh']);
const intervalId: Ref<ReturnType<typeof setInterval> | undefined> = ref();

const redirect = (redirectRoute: string) =>
  router.push({ name: redirectRoute });

onMounted(() => {
  startCountdown();
});

watch(
  props,
  () => {
    if (props.startCount) {
      startCountdown();
    } else if (props.stopCount) {
      clearInterval(intervalId.value);
      countdown.value = 70;
    }
  },
  { deep: true }
);

const countdown: Ref<number> = ref(70);

const displayTime = computed(() => {
  return formatTime(countdown.value);
});

function startCountdown() {
  intervalId.value = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearInterval(intervalId.value);
      redirect('login');
    }
  }, 1000);
}

function handleClick() {
  clearInterval(intervalId.value);
  emit('refresh');
  countdown.value = 70;
}
</script>
