<template>
  <hr :class="dividerClasses" />
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  height: {
    type: String,
    default: 'h-0.5'
  },
  marginTop: {
    type: String,
    default: 'my-4'
  },
  backgroundColor: {
    type: String,
    default: 'bg-gray-300'
  },
  borderColor: {
    type: String,
    default: 'border-b'
  },
  darkBackgroundColor: {
    type: String,
    default: 'dark:bg-gray-700'
  }
});

const dividerClasses = computed(() => [
  props.height,
  props.marginTop,
  props.backgroundColor,
  props.borderColor,
  props.darkBackgroundColor
]);
</script>
