import { permissionsType } from '@/apps/settings-config/types/settingsConfigTypes';

/**
 * Extracts permissions from the given permissions object based on a key.
 * @param permObject - The permissions object.
 * @param key - The key to filter permissions.(key==view | manage)
 * @returns - The extracted permissions.
 */
export function viewManage(
  permObject: Record<string, boolean>,
  key: 'view' | 'manage'
): Record<string, boolean> {
  const newObject: Record<string, boolean> = {};
  for (const perm in permObject) {
    if (perm.slice(0, perm.indexOf('_')) === key) {
      newObject[perm] = permObject[perm];
    }
  }
  return newObject;
}

//permission check always call with viewManage returned data
export function permCheck(permObject: permissionsType, key: string) {
  let check: boolean;
  if (key == 'all') {
    check = true;
    for (const perm in permObject) {
      check = permObject[perm] && check;
    }
    return check;
  } else if (key == 'atLeastOne') {
    check = false;
    for (const perm in permObject) {
      check = permObject[perm] || check;
    }
    return check;
  } else {
    return false;
  }
}

export function viewEmployeeManagement(settingsPerm: permissionsType): boolean {
  return permCheck(viewManage(settingsPerm, 'view'), 'atLeastOne');
}
export function viewTab(permissions: permissionsType): boolean {
  return permCheck(viewManage(permissions, 'view'), 'atLeastOne');
}
export function viewPersonalInfo(personalInfo: permissionsType, key: string) {
  return permCheck(viewManage(personalInfo, 'view'), key);
}
export function viewOtherInfo(
  personalInfo: permissionsType,
  workInfo: permissionsType,
  employeeDoc: permissionsType,
  employeeLoan: permissionsType,
  key = 'atLeastOne'
) {
  if (
    permCheck(viewManage(personalInfo, 'view'), key) ||
    permCheck(viewManage(workInfo, 'view'), key) ||
    permCheck(viewManage(employeeDoc, 'view'), key) ||
    permCheck(viewManage(employeeLoan, 'view'), key)
  )
    return true;
  else return false;
}

export function view(
  currentUserId: string,
  otherEmployeePerm: permissionsType
) {
  if (currentUserId || otherEmployeePerm.work_info) return true;
  else return false;
}
