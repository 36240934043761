<template>
  <div class="settings" style="border: 1px solid #e6e8f0">
    <div
      @click="store.showAll = !store.showAll"
      class="flex items-center cursor-pointer"
    >
      <div class="-ml-5">
        <svg
          width="80"
          height="40"
          viewBox="0 0 48 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="4" width="40" height="40" rx="8" fill="white" />
          <rect x="17" y="15" width="30" height="3" rx="1" fill="#DD5928" />
          <rect x="17" y="25" width="30" height="3" rx="1" fill="#DD5928" />
          <rect x="17" y="35" width="30" height="3" rx="1" fill="#DD5928" />
        </svg>
      </div>

      <p
        class="
          mt-2
          text-[27px]
          N700
          font-bold
          sm:hidden
          md:hidden
          lg:block
          xl:block
          mr-24
        "
        v-if="store.showAll"
      >
        AmaliTech
      </p>
    </div>
    <HorizontalLine />
    <div
      v-if="viewResource && !env.VUE_APP_RESOURCE_MANAGER"
      class="flex flex-col justify-between h-[80vh]"
    >
      <div>
        <NavigationComponent
          :item="{ label: 'Documents', route: '/all-folder' }"
          @mouseover="toggleOnDocuments(true)"
          @mouseout="toggleOnDocuments(false)"
        >
          <DocumentsLogo
            :color="
              onDocuments || $route.path === '/all-folder'
                ? '#DD5928'
                : '#8F95B2'
            "
          />
        </NavigationComponent>
      </div>
      <div class="">
        <NavigationComponent
          :item="{ label: 'Settings', route: 'settings' }"
          @mouseover="toggleOnSettings(true)"
          @mouseout="toggleOnSettings(false)"
        >
          <SettingsIcon :color="onSettings ? '#DD5928' : '#8F95B2'" />
        </NavigationComponent>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAccessStore } from '@/apps/authentication/store/store_access';
import { viewTab } from '../helpers/newGroupPermissions';
import DocumentsLogo from '../assets/DocumentSvg.vue';
import SettingsIcon from '../assets/SettingsIcon.vue';
import NavigationComponent from './NavigationComponent.vue';
import { ref } from 'vue';
import { useStore } from '../store';
import HorizontalLine from '@/ui-kit/HorizontalLine.vue';

// user permissions
const emits = defineEmits(['appSelected']);
const Access = useAccessStore();
const appsPerm = Access.permissions.resource_manager;
const store = useStore();

const env = process.env;
const viewResource = env.VUE_APP_STAGING
  ? appsPerm.view_talent_pool ||
    appsPerm.view_skills_manager ||
    appsPerm.view_client_manager ||
    appsPerm.view_project_manager
  : viewTab(appsPerm);

const onDocuments = ref(false);
const onSettings = ref(false);

const toggleOnDocuments = (status: boolean) => {
  onDocuments.value = status;
};

const toggleOnSettings = (status: boolean) => {
  onSettings.value = status;
};
</script>

<style scoped>
.settings {
  height: 100vh;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  padding: 23px 23px 0 23px;
  color: #474d66;
  background-color: white;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.settings::-webkit-scrollbar {
  display: none;
}

ul {
  margin: 19px 0;
}

li {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.H700 {
  z-index: 2;
}

@media screen and (min-width: 670px) and (max-width: 768px) {
  .menu {
    padding: 10px;
  }
}

@media screen and (max-width: 670px) {
  .menu {
    position: relative;
    top: 0;
    height: 40px;
    left: 5px;
    border: 1px solid #d8dae5;
    border-radius: 8px;
  }

  .menu_view {
    width: auto;
  }
}
</style>
