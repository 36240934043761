import PageNotFound from '../../PageNotFound.vue';

export default [
  {
    path: '/all-folder',
    name: 'all-folders',
    meta: { auth: true },
    component: () =>
      import(/* webpackChunkName: "directory" */ '../views/CompanyFolders.vue')
  },

  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    meta: { auth: true },
    component: PageNotFound
  }
];
