/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      return;
      'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB';
    },
    registered() {
      return 'Service worker has been registered.';
    },
    cached() {
      return 'Content has been cached for offline use.';
    },
    updatefound() {
      return 'New content is downloading.';
    },
    updated() {
      return 'New content is available; please refresh.';
    },
    offline() {
      return;
      ('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      return 'Error during service worker registration:' + error;
    }
  });
}
