<template>
  <RouterLink :to="`${item?.route}`" class="">
    <div
      class="
        flex
        items-center
        gap-4
        rounded-lg
        mr-4
        cursor-pointer
        my-4
        py-3
        px-4
        hover:bg-[#fff0ea]
        w-full
      "
      :class="
        $route.path === item?.route && store.showAll ? 'bg-[#fff0ea]' : ''
      "
    >
      <div :title="item?.label" v-if="!store.showAll">
        <slot></slot>
      </div>

      <div v-else>
        <slot></slot>
      </div>
      <span
        class="
          text-base
          sm:hidden
          md:hidden
          lg:block
          xl:block
          whitespace-nowrap
        "
        :class="$route.path === item?.route ? 'O400' : ''"
        v-if="store.showAll"
        >{{ item?.label }}</span
      >
    </div>
  </RouterLink>
</template>

<script setup lang="ts">
import { PropType } from 'vue';
import { useStore } from '../store';

const store = useStore();
defineProps({
  item: {
    type: Object as PropType<{ label: string; route: string }>
  }
});
</script>

<style scoped></style>
