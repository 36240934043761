import policiesDocumentRoutes from '@/apps/policies-and-documents/router/policiesDocumentsRoutes';
import settingRoutes from '@/apps/settings-config/router/settingRoutes';
// import staffDirectoryRoutes from "@/apps/staff-directory/router/staffDirectoryRoutes";
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageNotFound from '../apps/authentication/components/PageNotFound.vue';
import { getToken, getUserid, isLoggedIn, logout } from '../services/auth';
import { encodeUrlPath } from '@/helpers/utils';

const route: Array<RouteRecordRaw> = [
  {
    path: '/logout',
    name: 'logout',
    alias: '/logout',
    component: () => '',
    meta: { auth: false },
    beforeEnter: (_to, _from, next) => {
      logout();
      next('/login');
    }
  },
  {
    path: '/login',
    name: 'login',
    alias: '/login',
    component: () =>
      import(
        /* webpackChunkName: "sign-in" */ '../apps/authentication/components/SignIn.vue'
      ),
    meta: { auth: false }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ '../apps/authentication/components/ForgotPassword.vue'
      )
  },
  {
    path: '/sending-email',
    name: 'SendingEmail',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/components/SendingEmail.vue'
      )
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/components/ChangePassword.vue'
      )
  },
  {
    path: '/create-password',
    name: 'CreatePassword',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/views/createPassword.vue'
      )
  },
  // {
  //   path: "/",
  //   alias: "/home",
  //   name: "home",
  //   component: () =>
  //     window.location.href = `${process.env.VUE_APP_HOMEPAGE}/auth?token=${getToken()}`,
  //     //  import(/* webpackChunkName: "home" */ "@/apps/HomePage.vue"),
  //   meta: { auth: true },
  //   beforeEnter: (_to, _from, next) => {
  //     if(isLoggedIn()) {
  //       window.location.href = `${process.env.VUE_APP_HOMEPAGE}/auth?token=${getToken()}`;
  //     } else {
  //       next();
  //     }
  //   }
  // },
  {
    path: '/legacy',
    name: 'legacy',
    component: () =>
      (window.location.href = `${
        process.env.VUE_APP_ERP_API
      }/auth?token=${getToken()}`),
    //  import(/* webpackChunkName: "legacy" */ "@/apps/HomePage.vue"),
    meta: { auth: true },
    beforeEnter: (_to, _from, next) => {
      if (isLoggedIn()) {
        window.location.href = `${
          process.env.VUE_APP_ERP_API
        }/auth?token=${getToken()}`;
      } else {
        next();
      }
    }
  },
  // {
  //   path: "/",
  //   alias: "/home",
  //   name: "home",
  //   meta: { auth: true },
  //   component: () =>
  //   import(
  //     /* webpackChunkName: "signin" */ "../apps/homepage/views/HomePageDashboard.vue"
  //   ),
  // },

  // {
  //   path: "/notification",
  //   name: "NotificationView",
  //   meta: { auth: true },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "config" */ "../apps/performance-assessment/views/NotificationView.vue"
  //     ),
  // },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ '../apps/authentication/components/ForgotPassword.vue'
      )
  },
  // {
  //   path: "/add-employee",
  //   name: "add-employee",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "directory" */ "../apps/staff-directory/views/AddEmployee.vue"
  //     ),
  //   meta: { auth: true },
  // },
  // {
  //   path: "/edit-profile/:id",
  //   name: "edit-profile",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "directory" */ "../apps/staff-directory/views/EditProfile.vue"
  //     ),
  //   meta: { auth: true },
  // },

  {
    path: '/sending-email',
    name: 'SendingEmail',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/components/SendingEmail.vue'
      )
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/components/ChangePassword.vue'
      )
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../apps/authentication/components/ResetPassword.vue'
      )
  },
  // {
  //   path: "/learnmore",
  //   name: "Learn More",
  //   meta: { auth: true },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "config" */ "../apps/staff-directory/components/LearnMore.vue"
  //     ),
  // },

  {
    path: '/migration-service',
    name: 'Migration',
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ '../apps/migration-service/views/MigrationPage.vue'
      )
  },
  {
    path: '/add-query/:id',
    name: 'Query',
    meta: { auth: true },
    component: () =>
      import(
        /* webpackChunkName: "config" */ '../apps/migration-service/views/AddQuery.vue'
      )
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal`)
  },
  {
    path: '/request-loan',
    name: 'request-loan',
    meta: { auth: true },
    component: () =>
      (window.location.href = `/view-employee/${encodeUrlPath(
        String(getUserid())
      )}/personal/loans`)
  },

  {
    path: '/:catchAll(.*)*',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: { auth: true }
  }

  /** End of Auth routes */
];

const env = process.env;

export const routes = route
  .concat(policiesDocumentRoutes)
  .concat(settingRoutes);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, _from, next) => {
  if (to.meta.auth && !isLoggedIn()) {
    const intendedRoute = to.path;
    next('/login?redirect=' + encodeURIComponent(intendedRoute));
  } else {
    next();
  }
});

export default router;
