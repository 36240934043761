<template>
  <div class="mobile_div layout pb-4" :class="{ main_view: homeConditions }">
    <!-- <side-nav v-if="homeConditions && !store.onboardingPending" /> -->
    <AppsComponent v-if="homeConditions && !store.onboardingPending" />
    <!-- <div class="w-full"> -->
    <!-- <nav-bar
      v-if="homeConditions && $router.currentRoute.value.name != 'home'"
    /> -->

    <div
      class="container mx-auto pb-4 overflow-auto"
      :class="{ main_page: homeConditions, homePage: !homeConditions }"
    >
      <TopNavbar v-if="homeConditions && !store.onboardingPending" />
      <div class="p-4 h-full BG0 rounded-lg overflow-scroll mb-4">
        <router-link to="/"></router-link>
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" :key="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
    <!-- </div> -->
  </div>
  <GDialog v-model="showExpiringDialog" max-width="366">
    <sessionExpiredComponent
      @refresh="handleRefresh"
      :startCount="showExpiringDialog"
      :stopCount="!showExpiringDialog"
    />
  </GDialog>
</template>

<script setup lang="ts">
import { ARMS_REFRESH_TOKEN } from '@/graphql/mutations/authentication';
import { checkCookieExpiration, setToken } from '@/services/auth';
import { useMutation } from '@vue/apollo-composable';
import Cookies from 'cookies-ts';
import jwtDecode from 'jwt-decode';
import { Ref, computed, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import NavBar from './components/NavBar.vue';
import SideNav from './components/SideNav.vue';
import { useStore } from './store';
import sessionExpiredComponent from '@/apps/authentication/components/sessionExpired.vue';
import { provide } from 'vue';
import AppsComponent from './components/AppsComponent.vue';
import TopNavbar from './components/TopNavbar.vue';

const cookies = new Cookies();
const store = useStore();
const router = useRouter();
const route = useRoute();
const path = ref(window.location.pathname.slice(1));
const sessionExpired = ref(false);
const barActive = ref(true);
const ExpirationId: Ref<ReturnType<typeof setInterval> | undefined> =
  ref(undefined);
provide('session', { sessionExpired, setSessionExpired });

function setSessionExpired(state: boolean) {
  sessionExpired.value = state;
}

const redirect = (redirectRoute: string) =>
  router.push({ name: redirectRoute });

// const showExpiringDialog = computed(() => {
//   return sessionExpired.value && barActive.value;
// });
const showExpiringDialog = ref(sessionExpired.value && barActive.value);
watch([sessionExpired, barActive], () => {
  showExpiringDialog.value = sessionExpired.value && barActive.value;
});

watch(showExpiringDialog, (val) => {
  if (!val && barActive.value) {
    handleRefresh();
  }
});

const homeConditions = computed(() => {
  return (
    router.currentRoute.value.name != 'login' &&
    router.currentRoute.value.name != 'forgotPassword' &&
    router.currentRoute.value.name != 'CreatePassword' &&
    router.currentRoute.value.name != 'ChangePassword' &&
    router.currentRoute.value.name != 'ResetPassword' &&
    router.currentRoute.value.name != 'SendingEmail'
  );
});
// router.currentRoute.
watch(router.currentRoute, (val) => {
  const active = val.path.substring(1);
  if (
    active == 'login' ||
    active == 'forgot-password' ||
    active == 'change-password' ||
    active == 'create-password' ||
    active == 'sending-email' ||
    active == 'reset-password' ||
    active == 'welcome' ||
    active == ''
  )
    barActive.value = false;
  else barActive.value = true;
});

watch(
  sessionExpired,
  () => {
    clearInterval(ExpirationId.value);
    if (
      !(
        path.value == 'login' ||
        path.value == 'forgot-password' ||
        path.value == 'change-password' ||
        path.value == 'create-password' ||
        path.value == 'sending-email' ||
        path.value == 'reset-password' ||
        path.value == 'welcome' ||
        route.query.redirect ||
        path.value == ''
      ) &&
      !sessionExpired.value
    ) {
      ExpirationId.value = setInterval(() => {
        const expired = checkCookieExpiration();
        if (expired) {
          clearInterval(ExpirationId.value);
          sessionExpired.value = true;
        }
      }, 5000);
    }
  },
  { immediate: true }
);

const refresh = useMutation(ARMS_REFRESH_TOKEN);
refresh.onDone(({ data }) => {
  setToken(
    data.armsRefreshToken.access_token,
    data.armsRefreshToken.refresh_token,
    jwtDecode(data.armsRefreshToken.access_token)
  );
  sessionExpired.value = false;
});
refresh.onError(() => {
  redirect('login');
});

const handleRefresh = () => {
  refresh.mutate({ token: cookies.get('REFRESH_TOKEN') });
};
</script>

<style lang="scss">
body {
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 0px;
    height: 0;
  }
}

::-webkit-scrollbar {
  width: 0px;
  height: 0;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // border: 1px solid green;
  height: 100%;
}

* {
  box-sizing: border-box;
}

* {
  scrollbar-width: 8px;
  scrollbar-color: #d8dae5 #d8dae5;
}

*::-webkit-scrollbar {
  width: 8px;
  background-color: #d8dae5;
}

*::-webkit-scrollbar-thumb {
  background-color: #d8dae5;
}

*::-webkit-scrollbar-track {
  background-color: #fff;
}

.main_view {
  background-color: #d8dae5;
}

.main_page {
  box-sizing: border-box;
  // overflow: auto;
  margin-left: 100px;
  margin-top: 23px;
  height: 100vh;

  // height: 89vh;
}

.homePage {
  height: 100dvh;
  box-sizing: border-box;
}

.layout {
  width: 100%;
}

nav {
  padding: 30px;
}

a {
  color: #dd5928;

  &.router-link-exact-active {
    color: #dd5928;
  }
}

// BUTTON STYLYING
.btn-shadow {
  box-shadow: 0px 4px 10px -5px rgba(221, 89, 40, 0.3);
}

.btn-shadow:disabled {
  box-shadow: none;
}

.btn-shadow:hover {
  box-shadow: none;
}

/* SPACING */
// Margin top scales
.SPC-MT-100 {
  margin-top: 8px;
}

.SPC-MT-200 {
  margin-top: 16px;
}

.SPC-MT-300 {
  margin-top: 24px;
}

.SPC-MT-400 {
  margin-top: 32px;
}

.SPC-MT-500 {
  margin-top: 40px;
}

.SPC-MT-600 {
  margin-top: 48px;
}

// Margin bottom Scales
.SPC-MB-100 {
  margin-bottom: 8px;
}

.SPC-MB-200 {
  margin-bottom: 16px;
}

.SPC-MB-300 {
  margin-bottom: 24px;
}

.SPC-MB-400 {
  margin-bottom: 32px;
}

.SPC-MB-500 {
  margin-bottom: 40px;
}

.SPC-MB-600 {
  margin-bottom: 48px;
}

// Margin Left Scales
.SPC-ML-100 {
  margin-left: 8px;
}

.SPC-ML-200 {
  margin-left: 16px;
}

.SPC-ML-300 {
  margin-left: 24px;
}

.SPC-ML-400 {
  margin-left: 32px;
}

.SPC-ML-500 {
  margin-left: 40px;
}

.SPC-ML-600 {
  margin-left: 48px;
}

// Margin Right Scales
.SPC-MR-100 {
  margin-right: 8px;
}

.SPC-MR-200 {
  margin-right: 16px;
}

.SPC-MR-300 {
  margin-right: 24px;
}

.SPC-MR-400 {
  margin-right: 32px;
}

.SPC-MR-500 {
  margin-right: 40px;
}

.SPC-MR-600 {
  margin-right: 48px;
}

/* FONTSTYLES */
.H900 {
  font-weight: 700;
  font-size: 2.074rem;
  line-height: 40px;
}

.H800 {
  font-weight: 700;
  font-size: 1.728rem;
  line-height: 32px;
}

.H700 {
  font-weight: 700;
  font-size: 1.44rem;
  line-height: 28px;
}

.H600 {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 24px;
}

.H500 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
}

.H400 {
  font-weight: 700;
  font-size: 0.833rem;
  line-height: 16px;
}

.H300 {
  font-weight: 700;
  font-size: 0.694rem;
  line-height: 12px;
}

.H200 {
  font-weight: 500;
  font-size: 0.694rem;
  line-height: 12px;
}

.H100 {
  font-weight: 700;
  font-size: 0.579rem;
  line-height: 12px;
}

.P300 {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 24px;
}

.P250 {
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
}

.P200 {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
}

.P100 {
  font-weight: 400;
  font-size: 0.833rem;
  line-height: 16px;
}

.P50 {
  font-weight: 400;
  font-size: 0.5813rem;
  line-height: 12px;
}

/* COLOURS */
/* Neutrals */
.N900 {
  color: #101840;
}

.N800 {
  color: #474d66;
}

.N700 {
  color: #696f8c;
}

.N600 {
  color: #8f95b2;
}

.N500 {
  color: #c1c4d6;
}

.N400 {
  color: #d8dae5;
}

.N300 {
  color: #e6e8f0;
}

.N200 {
  color: #edeff5;
}

.N100 {
  color: #f4f6fa;
}

.N75 {
  color: #f9fafc;
}

.N50 {
  color: #fafbff;
}

.N0 {
  color: #ffffff;
}

// Orange (ACCENT COLOR)
.O600 {
  color: #893516 !important;
}

.O500 {
  color: #b5461d !important;
}

.O400 {
  color: #dd5928 !important;
}

.O300 {
  color: #e47a53 !important;
}

.O200 {
  color: #eb9c7f !important;
}

.O100 {
  color: #f2beab !important;
}

.O50 {
  color: #f9e0d7 !important;
}

// Red
.R600 {
  color: #7d2828 !important;
}

.R500 {
  color: #a73636 !important;
}

.R400 {
  color: #d14343 !important;
}

.R300 {
  color: #ee9191 !important;
}

.R200 {
  color: #f4b6b6 !important;
}

.R100 {
  color: #f9dada !important;
}

.R50 {
  color: #fdf4f4 !important;
}

// Green
.G600 {
  color: #317159 !important;
}

.G500 {
  color: #429777 !important;
}

.G400 {
  color: #52bd94 !important;
}

.G300 {
  color: #a3e6cd !important;
}

.G200 {
  color: #dcf2ea !important;
}

.G100 {
  color: #eef8f4 !important;
}

.G50 {
  color: #f5fbf8 !important;
}

.mobile_div {
  display: flex;
}

.BG0 {
  background-color: #ffffff;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

label {
  margin-left: 0 !important;
}

/*@ mobile version*/
@media (max-width: 670px) {
  // .mobile_div {
  //   display: flex;
  //   flex-direction: column;
  // }

  .main_page {
    box-sizing: border-box;
    // overflow: auto;
    margin-left: 0px;
    margin-top: 70px;

    // height: 89vh;
  }
}
</style>
